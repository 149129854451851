import React, { useState, useEffect } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Iframe from "react-iframe";
import { Helmet } from "react-helmet";
import { Oban_Whiskey_Domain } from "../../constant";

const FindNearMe = ({ findNearMeKey }) => {
  const [iframeUrl, setiFrameUrl] = useState("");

  const getParameterByName = (name) => {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  };

  let BaseiFrameUrl =
    "https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12037990&type=local";
  let Latitude = getParameterByName("lat");
  let Langitude = getParameterByName("lng");

  const findNearMe = () => {
    if (Latitude && Langitude) {
      setiFrameUrl(BaseiFrameUrl + "&lat=" + Latitude + "&long=" + Langitude);
    } else {
      setiFrameUrl(BaseiFrameUrl);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    findNearMe();
  }, [iframeUrl]);
  return (
    <>
      <main className="main find-near-me">
        <Helmet>
          <title>{findNearMeKey.title}</title>
          <meta name="description" content={findNearMeKey.metaDescription} />
          <meta property="og:title" content={findNearMeKey.ogTitle} />
          <meta
            property="og:description"
            content={findNearMeKey.metaDescription}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${Oban_Whiskey_Domain + findNearMeKey.url}`}
          />
          <meta
            property="og:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/FB_social.jpg"}`}
          />
          <meta name="twitter:card" content={findNearMeKey.metaDescription} />
          <meta
            name="twitter:url"
            content={`${Oban_Whiskey_Domain + findNearMeKey.url}`}
          />
          <meta name="twitter:title" content={findNearMeKey.ogTitle} />
          <meta
            name="twitter:image"
            content={`${Oban_Whiskey_Domain + "/assets/images/TW_social.jpg"}`}
          />
        </Helmet>
        <section className="content find-near-me">
          <Container>
            <div className="where-to-buy-Headline">
              <h1 className="text-white-dark mb-5">Find Oban near you</h1>
            </div>
            <div className="find-section text-center">
              <Iframe
                url={iframeUrl}
                width="100%"
                height="800px"
                id="wtbIframe"
                className="wtbIframe"
                overflow="hidden"
                minHeight="20px"
              />
            </div>
          </Container>
        </section>
          <section className="home-last-section">
          <Container>
            <Row>
              <Col md={6} style={{position: 'relative'}}>
                <div className="text-content">
                  <h3 className="content-head">
                  You can find us where the Highlands meet the Islands.
                  </h3>
                  <p>
                  The Oban Distillery Visitor Center — Winner of the Association of Scottish Visitor Attractions’ Best Visitor Experience award in 2017 — offers tours and tastings for a range of budgets and interests.
                  </p>
                  <p>
                  Pay us a visit, hear our stories, and witness how one of the world’s oldest and finest whiskies is produced first hand.
                  </p>
                  <div className="cta-wrapper">
                    <a href="https://www.malts.com/en-gb/distilleries/oban/tours" className="inline-link" target="_blank"         rel="noreferrer">
                      Visit our distillery
                    </a>
                  </div>
                </div>
              </Col>
              <Col sx={6}>
                <div className="footer-image home">
                <Image
                    src={`${Oban_Whiskey_Domain}/assets/images/buy_bottom-p-500.jpeg`}
                    width="450px"
                    height="588px"
                    alt="home-bottom"
                    className="image-footer-responsive"
                  />
                </div>
              </Col>
            </Row>
            </Container>
          </section>
      </main>
    </>
  );
};

export default FindNearMe;
